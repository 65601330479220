<template>
  <div class="about-us">
    <el-image class='banner-img' :src="isChinese() ? bannerUrl : bannerEnUrl" :fit="'contain'"></el-image>

    <div class="w_container">
      <!-- 描述文案 -->
      <div>
        <p v-for="item in (isChinese() ?pList:enPList)" :key="item">
          {{ item }}
        </p>
      </div>

      <template v-if="showItem[0].cnt">
        <ItemTitleLeft :item="showItem[0]"/>
        <div class="container-center show-item-container">
          <el-image class="show-item-img-width" :src="showItem[0].cnt"></el-image>
        </div>
      </template>


      <ItemTitleLeft :item="showItem[1]"/>
      <div class="container-center show-item-container">
        <el-image class="show-item-img-width" :src="showItem[1].cnt"></el-image>
      </div>

      <!--      <ItemTitleLeft :item="showItem[2]" />-->
      <!--      <div class="container-center show-item-container">-->
      <!--        <video-player class="video-player-box"-->
      <!--                  ref="videoPlayer"-->
      <!--                  :options="playerOptions"-->
      <!--                  :playsinline="true">-->
      <!--        </video-player>-->
      <!--      </div>-->

      <ItemTitleLeft :item="showItem[2]" :marginLeft="20" style="margin: 150px 0 90px 0"/>
      <!-- <div class="container-center show-item-container">
        <el-row style="width: 100%;padding: 30px 0;background-color: #EEE;">
          <el-col :span="10">
            <el-row style="width: 100%;">
              <el-col :span="4" style="text-align: center;">
                <span class="cnt-style">如果您想要</span>
              </el-col>
              <el-col :span="16" style="padding-left: 10px;">
                <div class="cnt-style" v-for="(item, idx) in requirements" :key="idx">{{ item }}</div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <div class="item-label">联系人:</div>
            <div class="item-value">张锋</div>
            <div class="item-label">TEL:</div>
            <div class="item-value">17621714828</div>
          </el-col>
          <el-col :span="6">
            <el-row style="width: 100%;">
              <el-col :span="3" style="text-align: center;">
                <span>微信:</span>
              </el-col>
              <el-col :span="16" style="padding-left: 10px;">
                <el-image class="contact-code-img" fit="fill" :src="contactCode" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div> -->
    </div>

    <!-- 背景 -->
    <div class="contact-info">
      <div style="width: 100%;margin: 0 auto;background-color: #EEE;padding: 40px 0" v-if="isChinese()">
        <div class="container-center show-item-container">
          <el-row style="width: 70%;" class="row">
            <el-col :span="10">
              <el-row style="width: 100%;">
                <el-col :span="4" style="text-align: center;min-width: 70px;">
                  <span class="cnt-style">如果您想要</span>
                </el-col>
                <el-col :span="19" style="padding-left: 10px;">
                  <div class="cnt-style" v-for="(item, idx) in requirements" :key="idx">{{ item }}</div>
                </el-col>
              </el-row>
            </el-col>
<!--            <el-col :span="8">-->
<!--              <div class="item-label">联系人:</div>-->
<!--              <div class="item-value">张锋</div>-->
<!--              <div class="item-label">TEL:</div>-->
<!--              <div class="item-value">17621714828</div>-->
<!--            </el-col>-->
<!--            <el-col :span="6">-->
<!--              <el-row style="width: 100%;">-->
<!--                <el-col :span="3" style="text-align: center;min-width: 40px;">-->
<!--                  <span>微信:</span>-->
<!--                </el-col>-->
<!--                <el-col :span="16" style="padding-left: 10px;">-->
<!--                  <el-image class="contact-code-img" fit="fill" :src="contactCode"/>-->
<!--                </el-col>-->
<!--              </el-row>-->
<!--            </el-col>-->
          </el-row>
        </div>
      </div>

      <!-- 英文手机端 -->
      <div class="en-container" v-else>
        <div class="en-item" style="display: flex;align-items: flex-start">
          <img src="../assets/aboutus/address.png" alt="" class="img">
          <div style="margin-left:20px">
            <div>HT-ICHARGE Technology Co., Ltd</div>
            <div>No.168 Jiaotong North Road, Wujiang District.</div>
            <div>Suzhou City, Jiangsu Province 215200, China</div>
          </div>
        </div>
        <div class="en-item" style="display: flex;align-items: flex-start">
          <img src="../assets/aboutus/email.png" alt="" class="img">
          <div style="margin-left:20px">
            <div>icharge@hengtonggroup.com.cn</div>
            <div>www.hengtonggroup.com</div>
            <div>www.renwochong.cn</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 手机端显示 -->
    <div class="p_container">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="isChinese() ? showItem[0].tab : showItem[0].english" name="about">
          <ItemTitleLeft :item="showItem[0]"/>
          <div style="margin: 0 16px 100px;">
            <p v-for="item in (isChinese() ? pList: enPList)" :key="item">
              {{ item }}
            </p>
          </div>
        </el-tab-pane>

        <el-tab-pane :label="isChinese() ? showItem[1].tab : showItem[1].english" name="honor">
          <ItemTitleLeft :item="showItem[1]"/>
          <div class="container-center show-item-container">
            <el-image class="show-item-img-width" :src="showItem[1].cnt"></el-image>
          </div>
        </el-tab-pane>

        <el-tab-pane :label="isChinese() ? showItem[2].tab : showItem[2].english" name="path">
          <ItemTitleLeft :item="showItem[2]"/>
          <div class="container-center show-item-container">
            <el-image class="show-item-img-width" :src="showItem[2].cnt"></el-image>
          </div>
        </el-tab-pane>

        <el-tab-pane :label="isChinese() ? showItem[3].tab : showItem[3].english" name="video"
                     v-if="!isChinese() && showItem.length > 3">
          <ItemTitleLeft :item="showItem[3]"/>
          <div class="container-center show-item-container">
            <div style="width: 90%;margin:auto;display: flex;flex-direction:column;justify-content: space-around;font-weight: 400;
font-size: 13px;
color: #333333;line-height: 20px">
              <div style="display: flex;align-items: center">
                <img src="../assets/aboutus/address.png" alt="" style="width:35px ;height: 40px">
                <div style="margin-left:40px">
                  <div>HT-ICHARGE Technology Co., Ltd</div>
                  <div>No.168 Jiaotong North Road, Wujiang District.</div>
                  <div>Suzhou City, Jiangsu Province 215200, China</div>
                </div>
              </div>
              <div style="display: flex;align-items: center;margin-top:100px">
                <img src="../assets/aboutus/email.png" alt="" style="width:35px ;height: 40px">
                <div style="margin-left:40px">
                  <div>icharge@hengtonggroup.com.cn</div>
                  <div>www.hengtonggroup.com</div>
                  <div>www.renwochong.cn</div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!--        <el-tab-pane :label="showItem[3].tab" name="video" v-if="showItem.length > 3">-->
        <!--          <ItemTitleLeft :item="showItem[3]" />-->
        <!--          <div class="container-center show-item-container">-->
        <!--            <video-player class="video-player-box"-->
        <!--                      ref="videoPlayer"-->
        <!--                      :options="playerOptions"-->
        <!--                      :playsinline="true">-->
        <!--            </video-player>-->
        <!--          </div>-->
        <!--        </el-tab-pane>-->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import bus from '@/bus'
import ItemTitleLeft from "../components/ItemTitleLeft"
import {isChinese} from "@/utils";

export default {
  name: 'about-us',
  methods: {isChinese},
  components: {
    ItemTitleLeft
  },
  data() {
    return {
      bannerUrl: process.env.VUE_APP_OSS_URL + '/about_us_banner.png',
      bannerEnUrl: process.env.VUE_APP_OSS_URL + '/aboutus/about_us_banner_en.png',

      // pList: [
      //   "任我充为鼎充新能源品牌，隶属于亨通慧充众联科技有限公司，是中国 500 强亨通集团旗下控股子公司，鼎充新能源成立于 1994 年， 是服务于新能源汽车公交商旅、物流、分时租赁、出租等充电领域的高新技术企业。是提供贯穿全业务流程的研发、制造、建设、投资、运营、人流物流服务的综合服务商。致力于打造中国新能源快速充电网络、绿色运力平台等综合绿色生态体系。",
      //   "亨通集团，是服务于光纤光网、电力电网、大数据互联网、新能源新材料和金融投资等领域的创新型企业，位列中国企业500强、中国民企100强、全球光纤通信行业前3强。",
      //   "亨通鼎充聚焦“快充网络、绿色运力和绿色服务”三大板块，实现三化融合“平台化，物联网化，能源互联网化”，重点推动全国新能源智慧城市、智慧交通生态网络的建设，打造全球新能源+领先品牌，建设天蓝、地绿、水清的美丽中国，满足美好生活需要。",
      //   "快充网络板块：以新能源汽车运营及充电为主营业务，整合人、车、桩、场资源，打造生态智能的快速充电服务网络。同时构建新能源发电、电力应用、智能电网的车网交互三位一体的应用型能源互联网。",
      //   "绿色运力版块：凭借自主研发运营的新能源智慧物流交通平台，通过线上、线下的有效融合，管理调配平台车辆、司机、货源信息，为客户提供车、桩同行的销售、租赁、运营、维护和金融整体解决方案，真正实现运力整合、资源共享、降本增效。",
      //   "绿色服务版块：在电动公交、商旅、物流、城市项目等领域，拥有8年的实际运营服务经验。同时，推出“任我充”城市智能充电监控云平台和智能APP平台，提供绿色智能一站式服务（统一支付、充电、运营、租车、设备运维、能量管理、车辆调度、政府监管、互联互通、售车）。绿色服务网络遍及江苏、上海、陕西、湖北、四川、海南、山西、山东、东北等28个省，以及香港、以色列、白俄罗斯、保加利亚等国家及地区。亨通鼎充绿色服务网络正沿着“一带一路”走出去，加快国际化和全球化布局。",
      //   "亨通鼎充通过三大板块“快充网络、绿色运力和绿色服务”， 三化融合“平台化，物联网化，能源互联网化” 加速商业模式和三张网络的全国城市推广，通过“融合 、协同、创新、共赢”的理念，与客户共享共赢，实现我们共同的愿景“让每一个幸福家庭自由的呼吸！让每一辆电动汽车自由的奔跑！”"
      // ],
      pList: [
        '亨通慧充，中国企业500强亨通集团旗下子公司，国内最早从事新能源电动汽车充解决方案的企业之一、国家级高新技术企业，是一家集充电设备研发制造、设备销售、场站运营、售后服务为一体的全场景充电应用解决方案供应商。',
        '公司充电网络覆盖全国25个省级行政区，旗下“任我充”品牌已为百万用户提供快速便捷的充电服务。未来，亨通慧充将借助集团强大的实力、整合集团完善的产业链优势，在技术研发、产品升级、市场拓展、运营服务等方面持续发力，致力于构建绿色出行生态圈，让每个人都能体会到新能源带来的便利。'

      ],
      enPList: [
        'HT-ICHARGE, a subsidiary of Hengtong Group, is a leading enterprise in EV charging solutions. With a robust charging network, we provide reliable and efficient solutions, continuously pushing the boundaries of charging technology.',
        'Committed to carbon neutrality, HT-ICHARGE aims to reduce carbon emissions in transportation through advanced charging solutions. Leveraging the strengths of Hengtong Group, we strive to enhance our technology, expand our market, and build a green travel ecosystem for a more sustainable future.'
      ],

      showItem: [{
        title: '我们的荣誉',
        english: 'HONOR',
        cnt: process.env.VUE_APP_OSS_URL + '/aboutus/honor.png'
      }, {
        title: '我们的发展历程',
        english: 'DEVELOP PATH',
        cnt: isChinese() ? process.env.VUE_APP_OSS_URL + '/aboutus/path_1.png' : process.env.VUE_APP_OSS_URL + '/aboutus/path_en.png'
      },
        //   {
        //   title: '我们的宣传片',
        //   english: 'ADVERTISING VIDEO'
        // },
        {
          title: '联系我们',
          english: 'CONTACT US'
        }],

      contactCode: require('../assets/aboutus/contact-wx-code.jpg'),
      requirements: [
        '1）购买直流桩、交流桩；',
        '2）投资建设充电站；',
        '3）找人投资建站，您可提供建站的场地资源；',
        '4）有充电站供您的充电车辆资源充电；',
        '5）改善现有充电站运营现状',
        '等任意需求均可联系我们！'
      ],

      // 视频播放信息
      playerOptions: {
        // videojs options
        muted: true,
        // preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: "https://dc-sz-1.oss-cn-shenzhen.aliyuncs.com/resource/website/%E9%BC%8E%E5%85%85%E5%AE%A3%E4%BC%A0%E7%89%87.mp4"
        }],
        poster: "https://dc-sz-1.oss-cn-shenzhen.aliyuncs.com/resource/website/company_ui.png", // 封面
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
      },

      activeName: 'about'
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    bus.on('SCREEN_WIDTH_CHANGE', key => {
      let isPhone = key <= 600
      if (isPhone) {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/phone/about_us_banner.png'
        this.bannerEnUrl = process.env.VUE_APP_OSS_URL + '/aboutus/phone/about_us_banner_en.png'

        this.showItem = [{
          tab: '关于我们',
          title: '关于我们',
          english: 'ABOUT US'
        }, {
          tab: '荣誉',
          title: '我们的荣誉',
          english: 'HONOR',
          cnt: process.env.VUE_APP_OSS_URL + '/aboutus/phone/honor.png'
        }, {
          tab: '发展历程',
          title: '我们的发展历程',
          english: 'DEVELOP PATH',
          cnt: isChinese() ? process.env.VUE_APP_OSS_URL + '/aboutus/phone/path_1.png' : process.env.VUE_APP_OSS_URL + '/aboutus/phone/path_en.png'
        },
          //   {
          //   tab: '宣传片',
          //   title: '我们的宣传片',
          //   english: 'ADVERTISING VIDEO',
          // },
          {
            tab: '联系我们',
            title: '联系我们',
            english: 'CONTACT US',
          }]
      } else {
        this.bannerUrl = process.env.VUE_APP_OSS_URL + '/about_us_banner.png'
        this.bannerEnUrl = process.env.VUE_APP_OSS_URL + '/aboutus/about_us_banner_en.png'

        this.showItem = [{
          title: '我们的荣誉',
          english: 'HONOR',
          cnt: process.env.VUE_APP_OSS_URL + '/aboutus/honor.png'
        }, {
          title: '我们的发展历程',
          english: 'DEVELOP PATH',
          cnt: isChinese() ? process.env.VUE_APP_OSS_URL + '/aboutus/path_1.png' : process.env.VUE_APP_OSS_URL + '/aboutus/path_en.png'
        },

          {
            title: '联系我们',
            english: 'CONTACT US'
          }]
      }
    })
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
}
</script>

<style  scoped>
.about-us, .banner-img {
  width: 100%;
}

p {
  text-indent: 2em;
  text-align: justify;
  text-justify: inter-ideograph; /* IE */
  margin: 0;
  line-height: 2em;
}

.w_container {
  width: 70%;
  margin: 40px auto;
}

.p_container {
  display: none;
}

/* 联系我们 */
.cnt-style {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333;
  line-height: 24px;
}

.item-label {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #444;
  line-height: 18px;
}

.item-value {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #444;
  line-height: 60px;
}

.contact-code-img {
  height: 160px;
}

.contact-info {
  width: 100%;
  //padding: 50px 0 !important;
  margin-top: 0 !important;

  .en-container {
    //margin: 0 auto;
    display: flex;
    justify-content: space-around;
    font-weight: 400;
    font-size: 15px;
    color: #333333;
  }

  //height: 243px; padding: 28px 0; background: #EEE; margin-top: -10px;
}

</style>